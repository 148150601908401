/**********************
Global Styles
**********************/
*,
*:after,
*:before {
  box-sizing: border-box; }

/** Fixing bootstrap columns for safari **/
.row:after,
.row:before {
  display: inline-block !important; }

body {
  color: #000;
  font-size: 19px;
  line-height: 1.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "PT Sans Narrow";
  letter-spacing: 0;
  overflow-x: hidden; }

button {
  background-color: transparent;
  border: 0; }
  button:hover {
    cursor: pointer; }
  button:focus {
    outline: 0; }

a,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0; }

section {
  padding: 80px 0; }

.container {
  max-width: 1140px; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto; }

.embed-container embed,
.embed-container iframe,
.embed-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.section-title {
  font-family: "Holtwood One SC", serif;
  text-transform: uppercase;
  font-size: 40px;
  color: #000;
  font-weight: 700;
  margin-bottom: 20px;
  letter-spacing: 3px; }
  .section-title span {
    color: #007d99; }

.sub-heading {
  font-size: 30px;
  line-height: 1.4;
  letter-spacing: 3px;
  display: inline-block;
  text-transform: uppercase;
  color: #000;
  margin-bottom: 12px;
  font-weight: 700;
  font-family: 'PT Sans Narrow',sans-serif; }

strike {
  color: rgba(136, 136, 136, 0.7); }

.img-container {
  text-align: center; }

.section-header {
  margin-bottom: 30px; }

.sale-text {
  font-weight: 700;
  font-size: 50px;
  line-height: 1;
  margin: 20px 0 30px;
  color: #000; }
  .sale-text span {
    text-decoration: line-through;
    color: #c9c9c9;
    font-weight: 400; }

.modal {
  background-color: rgba(0, 0, 0, 0.8); }

/* Buttons */
.btn-prime,
.btn-prime:link,
.btn-prime:visited {
  padding: 10px 25px;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  display: inline-block;
  text-align: center;
  font-size: 14px;
  vertical-align: middle;
  box-shadow: none !important;
  outline: 0;
  font-family: proxima-nova, sans-serif;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 1px; }

.btn-prime-dark,
.btn-prime-dark:focus,
.btn-prime-dark:hover {
  border: 2px solid #007d99;
  background-color: #007d99;
  color: #fff; }

.btn-prime-border,
.btn-prime-border:focus,
.btn-prime-border:hover {
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff; }

.flex-container {
  -webkit-box-align: center;
  align-items: center; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: auto; }

.embed-container embed,
.embed-container iframe,
.embed-container object {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.link-color {
  color: #007d99;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-weight: 900; }
  .link-color:hover {
    color: #007d99;
    opacity: 0.8; }

@media only screen and (max-width: 767px) {
  section {
    padding: 60px 0; }
  .sub-heading {
    font-size: 17px; } }

@media only screen and (max-width: 600px) {
  .section-title {
    font-size: 26px; }
  .btn-prime {
    width: 100%; } }

@media only screen and (max-width: 410px) {
  .section-title {
    font-size: 24px; } }

.slick-dots li button:before {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-image: url("../img/dot-border.png");
  background-repeat: no-repeat;
  content: "";
  opacity: 1; }

.slick-dots li.slick-active button:before {
  background-image: url("../img/dot-full.png");
  background-repeat: no-repeat; }

.slick-track {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer; }

#forModal {
  padding-right: 0 !important;
  height: 100vh;
  width: 100vw;
  margin: 0; }
  #forModal .iframe-form {
    width: 100%;
    height: 100vh; }
  #forModal .modal-content {
    max-width: 800px;
    width: 100%;
    height: 100%;
    margin: 6% auto; }
  #forModal .modal-header {
    border-bottom: 0; }
  #forModal .modal-body {
    padding: 0; }

/**********************
Form Modal
**********************/
#formModal {
  padding-right: 0 !important; }
  #formModal .modal-header {
    -webkit-box-align: center;
    align-items: center; }
  #formModal .modal-body {
    padding-bottom: 30px; }
    #formModal .modal-body iframe {
      width: 100%; }
  #formModal button.btn-prime {
    width: 100%; }
  #formModal label {
    font-weight: 700;
    margin-bottom: 0; }
  #formModal .col-5 {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center; }
  #formModal p {
    color: #007D99;
    font-size: 13px;
    text-align: center; }
  #formModal .modal-footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; }

.modal-dialog {
  max-width: 100%;
  width: 100%;
  margin: 0; }

.modal-content {
  max-width: 600px;
  margin: 0 auto; }

body:not(.et-tb) #main-content .container, body:not(.et-tb-has-header) #main-content .container {
  padding-top: 0; }

/**********************
Navigation
**********************/
header {
  background-color: #fff;
  box-shadow: none;
  height: 70px; }
  header .container {
    height: 100%; }
  header #main-nav {
    padding: 10px 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    height: 100%; }
    header #main-nav ul.menu-list {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none; }
  header .navbar-dark .navbar-nav .nav-link:hover {
    background-color: transparent;
    color: #007d99; }
  header .navbar-dark .navbar-brand img {
    height: 48px; }
  header .buy-now {
    background-color: #007d99;
    color: #fff !important;
    text-transform: uppercase; }
  @media (max-width: 991px) {
    header .navbar-dark .navbar-brand img {
      height: 40px; } }

.nav-link,
.nav-link:active,
.nav-link:focus,
.nav-link:hover,
.nav-link:visited {
  color: #000;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.05em;
  padding: 10px 15px !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-family: proxima-nova, sans-serif;
  text-transform: uppercase; }
  .nav-link:hover,
  .nav-link:active:hover,
  .nav-link:focus:hover,
  .nav-link:hover:hover,
  .nav-link:visited:hover {
    color: #007d99; }

.navbar-dark .navbar-brand {
  font-weight: 700;
  color: #007d99;
  height: 100%;
  padding: 0; }

.navbar-brand {
  max-width: 77px;
  padding: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center; }

.navbar-dark .navbar-brand img {
  height: 70px; }

.navbar-nav .dropdown-menu {
  left: auto;
  right: 0;
  border: 0;
  border-radius: 0;
  margin-top: 0; }

.dropdown-item {
  background-color: #fff; }

.dropdown-menu.show {
  background-color: #fff;
  padding: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.dropdown-item.active,
.dropdown-item:active {
  background-color: #f8f8f8;
  color: #212529; }

.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background-color: #007d99; }

.cta-btn {
  background-color: #007d99; }

.cta-btn .nav-link {
  color: #fff !important; }

@media only screen and (max-width: 767px) {
  #main-nav {
    display: none !important; } }

/* Mobile Menu */
header {
  background-color: #fff;
  position: relative;
  box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.2); }

@media screen and (min-width: 992px) {
  .mobile-menu {
    display: none; } }

@media screen and (max-width: 991px) {
  .mobile-nav .btn-prime.btn-prime-dark {
    color: #fff;
    font-size: 26px;
    width: 100%;
    text-transform: uppercase;
    text-transform: inherit; }
  input#burger {
    display: none; }
  .mobile-menu .navbar-brand {
    font-weight: 700;
    color: #007d99; }
  .mobile-menu input:not(:checked) .mobile-links {
    display: none; }
  .mobile-menu input + label {
    position: fixed;
    top: 23px;
    right: 20px;
    height: 20px;
    width: 34px;
    z-index: 5;
    padding: 0;
    margin-bottom: 0; }
  .mobile-menu input + label span {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 60%;
    margin-top: -1px;
    left: 0;
    display: block;
    background: #000;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .mobile-menu input + label span:first-child {
    top: 4px; }
  .mobile-menu input + label span:last-child {
    top: 20px; }
  .mobile-menu label:hover {
    cursor: pointer; }
  .mobile-menu input:checked + label span {
    opacity: 0;
    top: 50%; }
  .mobile-menu input:checked + label span:first-child {
    opacity: 1;
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg); }
  .mobile-menu input:checked + label span:last-child {
    opacity: 1;
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); }
  .mobile-menu input ~ nav {
    background: #fff;
    box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    z-index: 3;
    overflow: hidden; }
  .mobile-menu nav {
    position: relative; }
  .mobile-menu input ~ nav > ul {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0;
    margin-bottom: 0;
    width: 80%; }
  .mobile-menu input ~ nav > ul > li {
    display: none; }
  .mobile-menu input ~ nav > ul > li > a {
    color: #000;
    font-weight: 700;
    display: block;
    padding: 12px;
    font-size: 25px;
    font-family: proxima-nova, sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .mobile-menu input:checked ~ nav {
    height: 100%;
    -webkit-transition-delay: 0s;
    transition-delay: 0s; }
  .mobile-links {
    list-style: none; }
  .mobile-menu input:checked ~ nav > ul > li {
    display: initial; }
  .mobile-menu .navbar-brand {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3000;
    height: 100%;
    padding: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center; }
  .mobile-menu input + label {
    right: 20px; }
  .mobile-menu .navbar-brand {
    left: 15px; } }

@media screen and (max-width: 575px) {
  .mobile-menu input ~ nav > ul > li > a,
  .mobile-nav .btn-prime.btn-prime-dark {
    font-size: 19px; } }

.btn-prime-border strike {
  color: #f7c0bf; }

/******************************
Hero
******************************/
.hero {
  position: relative;
  margin-top: 70px;
  padding: 0;
  color: #fff;
  text-align: center;
  overflow-x: hidden; }
  .hero::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4; }
  .hero .container {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1; }
  .hero a {
    display: inline-block; }
  .hero .text-area .section-title {
    font-size: 50px;
    color: #fff;
    margin-bottom: 10px; }
    .hero .text-area .section-title span {
      display: block;
      color: #fff; }
      .hero .text-area .section-title span:first-child {
        display: initial; }
  .hero .sub-heading {
    color: #fff;
    margin-bottom: 10px; }
  .hero .btn-prime:hover {
    opacity: 1; }

@media only screen and (max-width: 1199px) {
  .hero .text-area .section-title {
    font-size: 36px; } }

@media only screen and (max-width: 991px) {
  .hero p {
    color: #000; }
  .hero::after {
    opacity: 0; }
  .hero .container {
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: none;
    transform: none;
    z-index: 1;
    padding: 40px 15px; }
  .hero .text-area {
    margin-top: 0; }
  .hero .text-area .section-title {
    font-size: 30px;
    color: #000;
    margin-bottom: 16px; }
  .hero .text-area .section-title span:first-child {
    color: #007d99; }
  .hero .sub-heading,
  .hero .text-area .section-title span {
    color: #000; }
  .hero .btn-prime-border {
    border: 2px solid #000;
    color: #000; } }

@media only screen and (max-width: 600px) {
  .hero .text-area .section-title {
    font-size: 26px; }
  .hero .text-area .section-title span:first-child {
    display: block !important; }
  .hero .text-area .section-title span {
    display: initial; }
  .hero .btn-prime-dark {
    margin-bottom: 10px; } }

@media only screen and (max-width: 410px) {
  .hero .text-area .section-title {
    font-size: 20px; } }

/**********************
Video
**********************/
.video {
  background: #f4f4f4;
  text-align: center; }
  .video p {
    font-weight: 500; }
  .video .section-header {
    margin-bottom: 40px; }
    .video .section-header .section-title {
      margin-bottom: 0; }
  .video .cta-container {
    margin-top: 40px; }
  .video .main-video .video-container {
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center; }
  .video .main-video .img-container {
    position: relative; }
    .video .main-video .img-container::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: #000;
      opacity: 0.2; }
  .video .main-video .play-btn {
    position: absolute;
    max-width: 100px;
    z-index: 1; }
  .video.part-2 .section-header {
    margin-bottom: 16px; }
  .video.part-2 .container {
    background: transparent !important; }
  .video.part-2 ul {
    margin-bottom: 1rem;
    padding-left: 10px;
    list-style: none; }
    .video.part-2 ul li {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: start;
      align-items: flex-start;
      margin-bottom: 8px; }
      .video.part-2 ul li i {
        margin: 3px 7px 0 0;
        color: #007d99; }
  .video.part-2 .cta-container {
    margin-top: 30px; }

@media only screen and (max-width: 767px) {
  .video .section-header .section-title {
    font-size: 36px; } }

@media only screen and (max-width: 600px) {
  .video .section-header {
    margin-bottom: 20px; }
  .video .section-header .section-title {
    font-size: 26px; } }

/**********************
Included
**********************/
.how-it-works {
  position: relative;
  text-align: center;
  display: none; }
  .how-it-works .grid-row {
    margin: 40px auto;
    max-width: 700px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    /* Size of items defined inside container */ }
    .how-it-works .grid-row .item {
      background-color: #f4f4f4;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
      border: 1px solid rgba(0, 0, 0, 0.03);
      border-radius: 5px;
      padding: 20px 15px;
      min-height: 200px;
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: center;
      justify-content: center; }
      .how-it-works .grid-row .item .icon img {
        max-width: 30%;
        margin-bottom: 20px; }
      .how-it-works .grid-row .item .title {
        font-weight: 700;
        font-size: 32px; }
      .how-it-works .grid-row .item .des {
        max-width: 400px;
        margin: 0 auto; }
      .how-it-works .grid-row .item .cta-container {
        margin-top: auto;
        width: 100%; }

@media only screen and (max-width: 600px) {
  .how-it-works .grid-row {
    grid-template-columns: 1fr; } }

/******************************
Text Block
******************************/
.img-slider {
  padding: 0 0 30px; }

@media only screen and (max-width: 767px) {
  .img-slider {
    padding: 0; } }

.banner {
  padding: 40px 0; }

@media only screen and (max-width: 600px) {
  .banner {
    padding: 0 0 20px; } }

/**********************
About
**********************/
.about .section-title {
  font-size: 32px; }

.about .img-container {
  border: #fff solid 10px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1); }

@media only screen and (max-width: 991px) {
  .about {
    padding: 60px 0;
    text-align: center; }
    .about .flex-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      flex-direction: column-reverse; }
  .about .img-container {
    margin-top: 40px; } }

@media only screen and (max-width: 600px) {
  .about .section-title {
    font-size: 26px; } }

.contact-us .img-container {
  max-width: 600px;
  width: 100%; }

.contact-us .email {
  font-family: "Open Sans Condensed", sans-serif; }
  .contact-us .email h5 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 24px; }

@media only screen and (max-width: 991px) {
  .contact-us {
    text-align: center; }
    .contact-us .flex-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      flex-direction: column-reverse; }
    .contact-us .text-area {
      max-width: 600px;
      margin: 0 auto 30px; }
  .contact-us .img-container {
    margin: 40px auto 0; } }

/******************************
Nominate
******************************/
.student-nominate .section-title {
  font-size: 34px; }

.student-nominate ul {
  list-style: none;
  padding: 0; }

.student-nominate .img-container {
  border: #fff solid 10px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1); }

@media only screen and (max-width: 991px) {
  .student-nominate {
    text-align: center; }
    .student-nominate .text-area {
      max-width: 600px;
      margin: 0 auto 50px; }
    .student-nominate .img-container {
      max-width: 600px;
      margin: 0 auto; } }

@media only screen and (max-width: 600px) {
  .how-it-works .grid-row {
    max-width: 330px; } }

/**********************
Footer
**********************/
footer {
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 30px 0;
  font-size: 12px; }
  footer .social svg {
    font-size: 20px;
    margin: 0 5px 10px; }
    footer .social svg path {
      fill: #fff; }

@media only screen and (max-width: 991px) {
  footer {
    text-align: center; } }

/** MEDIA QUERIES **/
/******************************
Mobile - Landscape
******************************/
